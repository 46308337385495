import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import getProductsServerSide from '@/utils/pagesServerSide/getProductsServerSide';
import apiProducts from '@/api/products';
import { ProductsGalleryPage } from '@/components/templates';
import { getStore } from '@/utils/getStoreId';
import useTrackingQueue from '@/hooks/useTrackingQueue';
import createUUID from '@/utils/createUUID';
import renderGalleryInfo from '@/utils/renderGalleryInfo';
import PreLanding from '@/components/templates/PreLanding';

/* eslint-disable */
const Products = (props) => {
  const { landing, gender, keywords, initialReduxState, landingInfo } = props;
  const { executeEvent } = useTrackingQueue();
  useEffect(() => {
    if (landing) {
      setTimeout(() => {
        executeEvent('viewLanding', {
          landing,
          landingEventID: landingInfo.landingEventID,
          data: landingInfo
        });
      }, 5000);
    }
  }, []);
  if (props.page === 'custom-landing') {
    return <PreLanding />;
  }

  const { orderBy } = useSelector((state) => state.products);

  const { title, description } = renderGalleryInfo({
    gender,
    initialReduxState,
    keywords,
    landing,
    orderBy
  });
  return (
    <ProductsGalleryPage
      landingDescription={landingInfo.description}
      options={initialReduxState.products.filtersOptions}
      pagination={initialReduxState.products.pagination}
      title={title}
      description={description}
    />
  );
};

export async function getServerSideProps(context, gender = '') {
  const storeId = getStore(context?.resolvedUrl);
  //Slug es el primmer argumento del pathname, pero en ocaciones vendrá vacío
  //Esto ocurrirá cuando se estén cochando 1 ruta nomral con [...slugs]
  const slug = context.params?.slug || 'productos';
  const slugMicrosite = context.params?.slugMicrosite;
  // #######Ficha de producto#######
  const [id] = slug
    .replace(/[^0-9--]/g, '')
    .split('-')
    .slice(-1);
  if (id.length === 8 && id[0] == 4) {
    const { 1: idColorFromOldUrl } = slug
      .replace(/[^0-9--]/g, '')
      .split('-')
      .slice(-2);
    return {
      redirect: {
        destination: slug.replace(`-${idColorFromOldUrl}`, ''),
        permanent: true
      }
    };
  }

  // #######Galería de productos#######
  // TODO: Ver headers
  context.res.setHeader('Cache-Control', 's-maxage=1, stale-while-revalidate');
  const landingEventID = createUUID();
  const isLanding = await apiProducts.validateSlug(
    storeId,
    landingEventID,
    slug === 'productos' ? slugMicrosite : slug
  );

  return getProductsServerSide(
    context,
    gender,
    isLanding.landing ? (slug === 'productos' ? slugMicrosite : slug) : null,
    {
      landingEventID,
      ...isLanding
    }
  );
}

Products.propTypes = {
  landing: PropTypes.string,
  gender: PropTypes.string,
  keywords: PropTypes.array,

  initialReduxState: PropTypes.objectOf(PropTypes.any)
};

Products.defaultProps = {
  landing: '',
  gender: '',
  keywords: [],
  initialReduxState: {}
};
export default Products;
