import getProductsServerSide from '@/utils/pagesServerSide/getProductsServerSide';
import api from '@/api/products';
import createUUID from '@/utils/createUUID';
import isMicrosite from '@/utils/isMicrosite';
import getLandingPageServerSide from '@/utils/pagesServerSide/getLandingPageServerSide';
import { getStore } from '@/utils/getStoreId';

export { default } from '@/pages/productos';

export async function getServerSideProps(ctx) {
  const { slugMicrosite } = ctx.params;
  const storeId = getStore(ctx?.resolvedUrl);
  // Es una landing
  if (slugMicrosite === 'cyber-monday') {
    const now = new Date();
    const dDay = new Date('2024-11-04T03:00:00');
    if (now < dDay) {
      return getLandingPageServerSide(ctx);
    }
  }

  const landingEventID = createUUID();
  const isLanding = await api.validateSlug(
    storeId,
    landingEventID,
    slugMicrosite
  );

  if (isLanding.landing) {
    return getProductsServerSide(ctx, '', slugMicrosite, {
      landingEventID,
      ...isLanding
    });
  }
  const isSlugMicrosite = await isMicrosite(slugMicrosite);
  if (!isSlugMicrosite) {
    ctx.params.slugs = [...(ctx.params?.slugs || []), slugMicrosite];
    delete ctx.params.slugMicrosite;
  }
  return getProductsServerSide(ctx);
}
